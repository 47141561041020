/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div",
    p: "p"
  }, _provideComponents(), props.components), {TableOfContents, LandingPageCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  return React.createElement(React.Fragment, null, React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "the-ultimate-aid-for-your-ear",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#the-ultimate-aid-for-your-ear",
    "aria-label": "the ultimate aid for your ear permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "The Ultimate Aid for Your Ear"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Every year, tech companies come out with new and exciting devices for consumers to buy that are supposed to enrich their lives. How often does that actually happen? Apple comes out with a new iPhone each year, but how much different does it make your life than the phone you already had? It’s innovation, but it’s not LIFE-CHANGING innovation. For people suffering from hearing loss, it’s finally time for a new product that makes hearing customizable and convenient. Starkey’s Livio ", React.createElement(_components.a, {
    href: "/hearing-aids/",
    className: "c-md-a"
  }, "hearing aid"), " is a device that WILL enrich your life. If you like technology and messing around with settings on your smartphone, you are going to love the Livio."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "100-bluetooth-enabled",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#100-bluetooth-enabled",
    "aria-label": "100 bluetooth enabled permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "100% Bluetooth Enabled"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The name Livio means “blueness” and it couldn’t be more fitting because this hearing aid is ", React.createElement(_components.a, {
    href: "/hearing-aids/technology/bluetooth",
    className: "c-md-a"
  }, "Bluetooth enabled"), " so you can operate it using your cellphone! First you download Starkey’s app, Thrive, on your iOS or Android smartphone. From there, the possibilities are endless! Most people when they think about hearing loss, don’t think about brand new, exciting technology. Instead, they think about the earpiece, but they don’t consider how far the industry has come. Starkey is pioneering Bluetooth sound customization with the Livio."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "programmable-environments",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#programmable-environments",
    "aria-label": "programmable environments permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Programmable Environments"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "In Thrive, you can customize your settings for each environment you frequent. The sound customization is detailed and completely automatic using the Livio AI (Artificial Intelligence). If you go to the library every day at two in the afternoon, you can adjust your settings to the atmosphere so you can hear sounds that are much quieter. That’s a setting you don’t want every Saturday morning at nine when you meet your bowling team for a game. For that, you’ll want a different, distinct setting that is perfect for the environment. Using Thrive, you can program your hearing aid and let it LEARN your patterns and preferences."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "music-quality-unsurpassed",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#music-quality-unsurpassed",
    "aria-label": "music quality unsurpassed permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Music Quality Unsurpassed"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "When it comes to music lovers, Starkey provides a host of superior products. The Livio is no different. You can listen to songs the way they were meant to be heard. Crank up the Beethoven and raise the bass for Queen! Make your adjustments in the Thrive app. Use the equalizer to find the perfect settings as you listen. Change the volume of streaming devices from the app and jog on the treadmill, full volume, or keep it low while you collaborate on ideas with colleagues to give you extra motivation. The Livio is such an impressive piece of audio technology that you’d want it even if you didn’t need it!"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "health-and-body-monitoring",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#health-and-body-monitoring",
    "aria-label": "health and body monitoring permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Health and Body Monitoring"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The Livio goes far beyond a normal hearing aid. It’s not just for hearing! It’s like an Apple Watch in your ear! Livio monitors your vitals and your activity. It keeps track of your aural preferences and patterns in the Thrive app. You can check your stats and get a full update on your hearing ability and how many steps you took. It monitors your heart rate and has brain tracking for social engagements. If you are a bit unstable and are considered a fall-risk, the Livio can serve as fall detector and will alert your contacts to come help you or call emergency services. No need to buy separate devices, because when it comes to wearable technology, Livio is STACKED with features."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "just-about-perfect",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#just-about-perfect",
    "aria-label": "just about perfect permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Just About Perfect"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "So far, the Livio seems to be pretty perfect, right? Those are just the SPECIAL features. There’s still more to love, like being able to choose between the receiver-in-the-ear model or the behind-the-ear model. Your level of discretion is up to you! No matter what you choose, expect excellence."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "It has a rechargeable battery with up to 24 hours of high-quality hearing, so you don’t have to worry about missing anything. You can be at dinner and get invited to go dancing without worrying about loss of quality."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The Livio is durable too, using the patented NanoShield to protect it from moisture. Your lifestyle doesn’t have to change just because your hearing has. Go rock climbing in the rain! It’s the ultimate “set it and forget it” hearing aid."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "No feedback. No buzzing or other weird sounds that you don’t want. With the Livio, you get 100% high-quality sound that YOU control."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "freedom-at-your-fingertips",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#freedom-at-your-fingertips",
    "aria-label": "freedom at your fingertips permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Freedom at Your Fingertips"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "With all these AMAZING features and abilities, the Livio may seem unattainable, but that’s the best part! Contact us atghear.com to find out if the Livio is right for you! One of our hearing experts will assist you in getting the right hearing aid for you. You can even sign up for a 45 day risk-free of the Livio or any other hearing aid that is suitable for you. If you’re not satisfied, simply return the device for a full refund – no questions asked. So, don’t wait! You don’t know what you’re missing!"), "\n", React.createElement(LandingPageCta, {
    copy: "Try the Starkey Livio now",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
